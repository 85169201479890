$primary: #9e9855;
$red: #b85c39;
$white: #fff;

.cards {
  padding: 4rem;
  background: #000;
}

h2 {
  color: #fff;
  text-align: center;
}

.cards-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

Button.intro-button,
Button.faq-button,
Button.roadmap-button,
Button.join-button {
  background: $red;
  border: none;
  color: $white;
  width: 75px;
}
Button.join-button {
  width: 150px;
}

Button.intro-button:hover,
Button.faq-button:hover,
Button.roadmap-button:hover,
Button.join-button:hover {
  background: $red;
  border: none;
  color: $white;
  opacity: 0.7;
}

Button.button:focus,
Button.join-button:focus {
  background: $red;
  border: none;
  color: $white;
}
