$primary: #9e9855;
$white: #fff;

.profile-container {
  background: #000;
  padding: 4em 0 6em 0;
}

.profile {
  width: 600px;
  margin: 0em auto 0em auto;
}

input[type="checkbox"] {
  visibility: hidden;
}
label {
  cursor: pointer;
}
input[type="checkbox"] + label:before {
  border: 1px solid #333;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0.15em 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
}

input[type="checkbox"]#urgent:checked + label:before {
  background: transparent;
  color: red;
  content: "\2713";
  text-align: center;
}

#profile-close {
  color: lightgray;
}

#profile-close:hover {
  color: green;
  cursor: pointer;
}

Button.profile-button {
  background: $red;
  border: none;
  color: $white;
}

Button.profile-button:hover {
  background: $red;
  border: none;
  color: $white;
  opacity: 0.7;
}

Button.profile-button:focus {
  background: $red;
  border: none;
  color: $white;
}
