$primary: #9e9855;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "./sass/contact";
@import "./sass/cards";
@import "./sass/demo";
@import "./sass/hero";
@import "./sass/navbar";
@import "./sass/profile";
@import "./sass/roadmap";
@import "./sass/reset";
@import "./sass/signin";
@import "./sass/support";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}
