$black: #000000;
$dark: #0b0b0b;
$dark2: #1a1a1a;
$secondary: silver;
$inactive: #7f7f7f;
$primary: #9e9855;
$text: #a4a4a4;
$tertiary: #b85c39;
$white: #ffffff;

.hero-container {
  background: $black;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  padding-top: 5em;
}

.intro-text {
  font-size: 20px;
  color: #fff;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8em;
}

h1 {
  color: $white;
  text-align: center;
}

span {
  position: relative;
  right: 5em;
  width: 55%;
}

span#middle {
  position: relative;
  left: 6em;
  width: 45%;
}

span#last {
  position: relative;
  right: 5.5em;
  width: 45%;
}

#logo {
  margin-bottom: 8em;
  margin-top: 4em;
}

#logo path:nth-child(1) {
  stroke-dasharray: 548.7666015625px;
  stroke-dashoffset: 548.7666015625px;
  animation: line-anim 2s ease forwards 1.8s, fill-4 1.5s ease forwards 3.5s;
}

#logo path:nth-child(2) {
  stroke-dasharray: 472.2640075683594px;
  stroke-dashoffset: 472.2640075683594px;
  animation: line-anim 2s ease forwards 1.5s, fill-3 1.5s ease forwards 3.5s;
}

#logo path:nth-child(3) {
  stroke-dasharray: 467.0431823730469px;
  stroke-dashoffset: 467.0431823730469px;
  animation: line-anim 2s ease forwards 1.2s, fill-1 1.5s ease forwards 3.5s;
}

#logo path:nth-child(4) {
  stroke-dasharray: 433.7169189453125px;
  stroke-dashoffset: 433.7169189453125px;
  animation: line-anim 2s ease forwards 0.9s, fill-2 1.5s ease forwards 3.5s;
}

#logo path:nth-child(5) {
  stroke-dasharray: 242.62428283691406px;
  stroke-dashoffset: 242.62428283691406px;
  animation: line-anim 2s ease forwards 0.6s, fill-0 1.5s ease forwards 3.5s;
}

#logo path:nth-child(6) {
  stroke-dasharray: 151.68978881835938px;
  stroke-dashoffset: 151.68978881835938px;
  animation: line-anim 2s ease forwards 0.3s, fill-0 0.5s ease forwards 3.5s;
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-0 {
  from {
    fill: #000000;
  }
  to {
    fill: #000000;
  }
}

@keyframes fill-1 {
  from {
    fill: transparent;
  }
  to {
    fill: #9e9855;
  }
}

@keyframes fill-2 {
  from {
    fill: transparent;
  }
  to {
    fill: #6063ad;
  }
}
@keyframes fill-3 {
  from {
    fill: transparent;
  }
  to {
    fill: #b85c39;
  }
}
@keyframes fill-4 {
  from {
    fill: transparent;
  }
  to {
    fill: #5fb88c;
  }
}
@media screen and (max-width: 1800px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .hero-container {
    display: flex;
    flex-direction: column;
  }
  .hero-container > h1 {
    font-size: 50px;
    margin-top: 200px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-moble {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
  .intro-text {
    width: 80%;
    font-size: 10px;
    margin-bottom: 5px;
  }
}
.d-block {
  max-height: 250px;
  max-width: 250px;
  align-self: center;
}
