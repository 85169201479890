.signin-container {
  background: #000;
  padding: 12em 0;
}
.signin {
  width: 500px;
  margin: 0em auto 0em auto;
}

input[type="checkbox"]#remember:checked + label:before {
  background: transparent;
  color: #000;
  content: "\2713";
  text-align: center;
  margin-top: 0.2em;
}

Button.signin-button {
  background: $red;
  border: none;
  color: $white;
}

Button.signin-button:hover {
  background: $red;
  border: none;
  color: $white;
  opacity: 0.7;
}

Button.signin-button:focus {
  background: $red;
  border: none;
  color: $white;
}
