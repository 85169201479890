$primary: #9e9855;
$white: #fff;

.contact-container {
  background: #000;
  padding: 4em 0 6em 0;
}

.contact {
  width: 600px;
  margin: 0em auto 0em auto;
}

input[type="checkbox"] {
  visibility: hidden;
}
label {
  cursor: pointer;
}
input[type="checkbox"] + label:before {
  border: 1px solid #333;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
}
input[type="checkbox"]#front:checked + label:before {
  background: #9e9855;
  color: #fff;
  content: "\2713";
  text-align: center;
}
input[type="checkbox"]#back:checked + label:before {
  background: #6063ad;
  color: #fff;
  content: "\2713";
  text-align: center;
}
input[type="checkbox"]#writer:checked + label:before {
  background: #b85c39;
  color: #fff;
  content: "\2713";
  text-align: center;
}
input[type="checkbox"]#content:checked + label:before {
  background: #5fb88c;
  color: #fff;
  content: "\2713";
  text-align: center;
}
input[type="checkbox"]#social:checked + label:before {
  background: #6c0475;
  color: #fff;
  content: "\2713";
  text-align: center;
}
input[type="checkbox"]#other:checked + label:before {
  background: transparent;
  color: #000;
  content: "\2713";
  text-align: center;
}

#contact-close {
  color: lightgray;
}

#contact-close:hover {
  color: green;
  cursor: pointer;
}
