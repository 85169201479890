$primary: #9e9855;
$red: #b85c39;
$white: #fff;

.blinking {
  animation: blinkingButton 3s infinite;
}
@keyframes blinkingButton {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#brand {
  font-size: 0.75em;
  margin-left: 10em;
}

Button.signin,
Button.signout {
  background: $red;
  border: none;
  color: $white;
}

Button.signin:hover,
Button.signout:hover {
  background: $red;
  border: none;
  color: $white;
  opacity: 0.5;
}

Button.signin:focus {
  background: $red;
}
