.reset-container {
  background: #000;
  padding: 12em 0;
}
.reset {
  width: 500px;
  margin: 0em auto 0em auto;
}

Button.reset-button {
  background: $red;
  border: none;
  color: $white;
}

Button.reset-button:hover {
  background: $red;
  border: none;
  color: $white;
  opacity: 0.7;
}

Button.reset-button:focus {
  background: $red;
  border: none;
  color: $white;
}
